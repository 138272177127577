






























































import { Getter, Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import 'vue-date-pick/dist/vueDatePick.css';
import fecha from 'fecha';
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        PagePresetFullWidth: () => import('@/layouts/presets/FullWidth.vue'),
        ProductSample: () => import('@/components/product/ProductSample.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
        FormDate: () => import('@/components/forms/Date.vue'),
        ContactToggle: () => import('@/components/contact/ContactToggle.vue'),
    },
})
export default class PageCreateOrder extends Vue {
    @Getter('company/cart') cart!: Cart;
    @Getter('company/viewing') company!: Company;
    @Getter('pageLoading') pageLoading!: boolean;

    @Action('company/createOrder') createOrder!: (payload: CreateOrderPayload) => Promise<Auth>;

    i18n: string = 'views.order.create';

    success: boolean = false;

    form: CreateOrderPayload = {
        own_reference: '',
        shipping_date: fecha.format(new Date(), 'DD-MM-YYYY'),
        note: '',
    };

    days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    created() {
        this.form.shipping_date = fecha.format(this.nextPossibleDate(), 'DD-MM-YYYY');
    }

    nextPossibleDate() {
        const currentDate = new Date();
        let nextPossibleDate = null;
        let i = 1;

        while (nextPossibleDate == null && i < 8) {
            const futureDate = new Date(currentDate);
            futureDate.setDate(futureDate.getDate() + i);
            const dayName = this.days[futureDate.getDay()];
            nextPossibleDate = this.company.delivery_days.includes(dayName) ? futureDate : null;
            nextPossibleDate = this.company.delivery_days.includes(dayName) ? futureDate : null;
            i++;
        }

        if (nextPossibleDate == null) {
            nextPossibleDate = new Date();
            nextPossibleDate.setDate(nextPossibleDate.getDate() + 1);
        }

        return nextPossibleDate;
    }

    submitOrder() {
        const payload: CreateOrderPayload = cloneDeep(this.form);

        const date = fecha.parse(payload.shipping_date, 'DD-MM-YYYY');

        if (date != null) {
            payload.shipping_date = fecha.format(date, 'YYYY-MM-DD');
        }

        this.createOrder(payload).then(() => {
            this.success = true;
        });
        // .catch((e: ErrorResponse) => $form.handleErrors(e));
    }

    get maxDate() {
        const date = new Date();
        date.setDate(date.getDate() + 10);

        return fecha.format(date, 'YYYY-MM-DD');
    }
}
